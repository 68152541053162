<template>
  <span>
    <v-row dense justify="center">
      <v-col
        v-for="kpi in listaKpisCardInicial" 
        :key="kpi.value"
        cols="auto"
        class="flex-grow-1"
      >
        <v-card class="my-0">
          <v-card-title>
            <v-tooltip bottom v-if="traducaoIndicadores[kpi.value] != null">
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ kpi.text }}</h5>
              </template>
              <span >{{ traducaoIndicadores[kpi.value] }}</span>
            </v-tooltip>
            <h5 v-else >{{ kpi.text }}</h5>

            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosKpisTotalizados[kpi.value] || 0"
              :valorAnterior="dadosKpisTotalizadosCompetenciaAnterior[kpi.value] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{ dadosKpisTotalizados[kpi.value] | parseNumberToFloatBrIgnoringNaN }} 
              <span>{{ kpi.suffix }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-1">
      <v-col cols="12">
        <dashboard-servicos-visao-aneel-card-situacao-solicitacoes
          :dados="dadosSituacoesSolicitacoes"
          :exportingOptions="exportingOptions"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <dashboard-servicos-visao-aneel-card-pendentes 
          :dados="dadosCardPendentes"
          :competencias="categories"
          :prazoReguladoServico="prazoReguladoServico"
          :exportingOptions="exportingOptions"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <dashboard-servicos-visao-aneel-card-concluidas 
          :dados="dadosCardConcluidas"
          :competencias="categories"
          :prazoReguladoServico="prazoReguladoServico"
          :exportingOptions="exportingOptions"
        />
      </v-col>
    </v-row>
    
    <dashboard-servicos-visao-aneel-cards-kpis-por-competencia 
      :dados="dadosCardsKpisPorCompetencia"
      :categories="categories"
      :metasKpis="metasKpis"
      :exportingOptions="exportingOptions"
    />
  </span>
</template>

<script>

import DashboardServicosService from "@/services/DashboardServicosService";
import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);

import dayjs from 'dayjs';

export default {
  name: "DashboardServicosVisaoAneel",
  components: {
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue"),
    DashboardServicosVisaoAneelCardSituacaoSolicitacoes: () => import("@/components/dashboardServicos/DashboardServicosVisaoAneelCardSituacaoSolicitacoes.vue"),
    DashboardServicosVisaoAneelCardPendentes: () => import("@/components/dashboardServicos/DashboardServicosVisaoAneelCardPendentes.vue"),
    DashboardServicosVisaoAneelCardConcluidas: () => import("@/components/dashboardServicos/DashboardServicosVisaoAneelCardConcluidas.vue"),
    DashboardServicosVisaoAneelCardsKpisPorCompetencia: () => import("@/components/dashboardServicos/DashboardServicosVisaoAneelCardsKpisPorCompetencia.vue"),
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    servicoSelecionado: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    listaKpisCardInicial: [
      { value: "pendentes", text: "Pendentes", suffix: "" },
      { value: "pendentes_no_prazo", text: "Pendentes no Prazo", suffix: "" },
      { value: "pendentes_em_atraso", text: "Pendentes em Atraso", suffix: "" },
      { value: "psa", text: "PSA", suffix: "%" },
      { value: "pat", text: "PAT", suffix: "" },
      { value: "suspensos", text: "Suspensos", suffix: "" },
      { value: "concluidos", text: "Concluídos", suffix: "" },
      { value: "concluidos_no_prazo", text: "Concluídos no Prazo", suffix: "" },
      { value: "concluidos_fora_prazo", text: "Concluídos Fora Prazo", suffix: "" },
      { value: "prazo_medio_total", text: "Prazo Médio Total", suffix: "" },
      { value: "patc", text: "PATC", suffix: "" },
      { value: "solicitacoes", text: "Solicitações", suffix: "" },
      { value: "compensacao", text: "Compensação", suffix: "" },
    ],
    prazoReguladoServico: 0,
    rawPrazoReguladoServico: 0,
    dadosKpisTotalizados: {},
    dadosKpisTotalizadosCompetenciaAnterior: {},
    dadosSituacoesSolicitacoes: {},
    dadosCardPendentes: {},
    dadosCardConcluidas: {},
    dadosCardsKpisPorCompetencia: {},
    metasKpis: {},
    exportingOptions:{
      enabled: true,
        buttons: {
            contextButton: {
              align: 'left',
              verticalAlign: 'bottom',
              menuItems: [
              {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    }
              ]
            }
        }
    }
  }),
  async mounted() {
    await this.getPrazoReguladoServico();
    this.getDados();
  },
  methods: {
    getPrazoReguladoServico() {
      // Apenas atualiza os se for a visao selecionada
      if (this.activatedTab !== 1) return; 

      return IndicadoresServicosV2Service.getPrazoReguladoServico(this.servicoSelecionado)
        .then(res => {
          this.prazoReguladoServico    = Number(res.prazo_regulado);
          this.rawPrazoReguladoServico = res.prazo_regulado_raw;
        })
        .catch(err => {
          this.$toast.error('Erro ao recuperar Prazo Regulado do serviço selecionado.', '', {
            position: 'topRight'
          });
          throw err;
        });
    },
    getDados() {
      // Apenas atualiza os dados se for a visao selecionada
      if (this.activatedTab !== 1) return; 

      this.$emit("updateLoadingDados", true);

      Promise.all([
        this.getDadosKpisTotalizados(),
        this.getDadosKpisTotalizadosCompetenciaAnterior(),
        this.getMetasKpisGeral(),
        this.getDadosSituacaoSolicitacoes(),
        this.getDadosCardPendentes(),
        this.getDadosCardConcluidas(),
        this.getDadosCardsKpisPorCompetencia(),
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosKpisTotalizados() {
      return DashboardServicosService.getDadosKpisTotalizadosVisaoAneel(
        this.competencia,
        this.competencia,
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosKpisTotalizados = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosKpisTotalizadosCompetenciaAnterior() {
      return DashboardServicosService.getDadosKpisTotalizadosVisaoAneel(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'), 
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosKpisTotalizadosCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisGeral() {
      return DashboardServicosService.getMetasKpisGeral(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.servicoSelecionado
      )
        .then(res => {
          this.metasKpis = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosSituacaoSolicitacoes() {
      return DashboardServicosService.getDadosSituacaoSolicitacoes(
        this.competencia,
        this.competencia,
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosSituacoesSolicitacoes = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosCardPendentes() {
      return DashboardServicosService.getDadosCardPendentes(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosCardPendentes = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosCardConcluidas() {
      return DashboardServicosService.getDadosCardConcluidas(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosCardConcluidas = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosCardsKpisPorCompetencia() {
      return DashboardServicosService.getDadosCardsKpisPorCompetencia(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.servicoSelecionado
      )
        .then(res => {
          this.dadosCardsKpisPorCompetencia = res;
        })
        .catch(err => {
          throw err;
        })
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    async servicoSelecionado() {
      await this.getPrazoReguladoServico();
      this.getDados();
    }
  }
}

</script>

<style>

</style>